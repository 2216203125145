.feature{
    display: flex;
    width: 31%;
    background-color: color(display-p3 0.525 0.99 0.982 / 0.1);
    border-radius: 16px;
    padding: 24px 12px;
    box-sizing: border-box;
}
.icon{
    width: 64px;
    margin-right: 12px;
}
.content{
    width: calc(100% - 80px);
}
.title{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 20px;
    color: var(--accent);
}
.description{
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 20px;
    color: #858788;
}