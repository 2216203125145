/* Background.module.css */
.canvasWrapper {
    /* background-image: radial-gradient(#003333 25%, #000); */
    display: block;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;    
}

.canvasGridCells,
.canvasGridLines {
    display: block;
    position: absolute;
}
