.footer{
    width: calc(100% - 60px);
    color: #fff;
    z-index: 1;
}
.upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left,
.middle,
.right{
    width: 25%;
}
/* Title */
.title{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #00fff9;
    margin-bottom: 16px;
}
.record{
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}
.contact_icon{
    margin-right: 12px;
}

/* LEFT */
.buttons_block{
    display: flex;
}

.telegram,
.whatsapp{
    border-radius: 16px;
    padding: 8px 32px;
    background: rgba(0, 176, 242, 0.25);
    display: flex;
    align-items: center;
}
.telegram{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    color: #00b0f2;
    margin-right: 16px;
}
.whatsapp{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    color: #00e510;
}
.telegram img,
.whatsapp img{
    margin-right: 8px;
}

/* MIDDLE */
.working_hours{
    margin-right: 16px;
}

/* RIGHT */
.country{
    margin: 12px 0 0 36px;
}
.buttons_maps_block{
    display: flex;
    margin: 24px 0;
    justify-content: space-between;
    /* width: 75%; */
}
.map_item{
    width: 33%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.map_item img{
    margin-bottom: 12px;
}

.map_scheme_block{
    display: flex;
    justify-content: center;
    /* width: 75%; */
}
.map_scheme_button{
    border: 1px solid #00796d;
    border-radius: 16px;
    padding: 8px 32px;
    margin: 0 auto;

    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    background: linear-gradient(135deg, #38c682 0%, #00796d 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* BOTTOM */
.bottom_block{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.28);
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
    margin: 32px 0 0 0;
    padding: 16px 0;
}
.bottom_left{
    display: flex;
    align-items: center;
}
.label_follow{
    margin-right: 16px;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}
.bottom_left img{
    margin: 0 16px;
    cursor: pointer;
}
.bottom_right{
    display: flex;
    align-items: center;
}
.bottom_right button{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #fff;
    margin-left: 48px;
}
/*  */
@media only screen and (max-width: 850px){
    
}