.block{
    position: relative;
    z-index: 1;
    width: calc(100% - 60px);
    border-radius: 24px;
    box-sizing: border-box;
    padding: 32px 24px;
    background: linear-gradient(180deg, rgba(0, 255, 249, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 80px;
}
.content{
    border-radius: 24px;
    padding: 23px 35px;
    backdrop-filter: blur(4px);
    background: linear-gradient(180deg, rgba(153, 153, 153, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    color: #fff;
    width: 80%;
    margin-bottom: 24px;
}
.subtext{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #00fff9;
    width: 60%;
}
.btn{
    border: 1.21px solid #00fff9;
    border-radius: 7px;
    padding: 20px 106px;
    background: #00fff9;

    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #0d1417;
}