.main{
    z-index: 1;
    width: calc(100% - 60px);
    height: calc(100vh - 100px);
    box-sizing: border-box;
    display: flex;
    position: relative;
}

.title{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 80px;
    text-transform: uppercase;
    color: #fff;
    /* text-shadow: 0 4px 41px 0 rgba(255, 255, 255, 0.25); */
    margin-bottom: 24px;
}
.subtitle{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 32px;
    text-transform: uppercase;
    color: #00fff9;
}
.left{
    width: 50%;
    padding-top: 100px;
}
.right{
    width: 50%;
    padding-top: 70px;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.card_bg{
    width: 85%;
    position: absolute;
    z-index: 0;
    top: 0;
}
.header{
    display: flex;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 24px;
    width: 60%;
    margin-top: 5%;
    z-index: 1;
}
.headerLeft{
    width: 75%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}
.headerRight{
    width: 25%;
    flex-direction: column;
}

.headerLeft .text{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #4bfbf7;
}
.headerLeft .subtext{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    color: #fff;
}
.headerRight .text,
.headerRight .subtext{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}


/* BODY */
.body{
    z-index: 1;
    margin: 32px 0;
    width: 66%;
}
.body img{
    width: 100%;
    border-radius: 12px;
}
.btn{
    background: #00fff9;
    border-radius: 8px;
    padding: 20px 106px;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #0d1417;
    cursor: pointer;
}

/* FOOTER */
.footer{
    z-index: 1;
}