.section{
    color: #fff;
    z-index: 1;
    width:  calc(100% - 60px);
}
.body{
    display: flex;
    justify-content: space-between;
}
.heading{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    margin-bottom: 32px;
    text-align: center;
    margin-top: 80px;
}
/* LEFT */
.left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
}
.logo{
    max-width: 200px;
    margin-bottom: 16px;
}
.text{
    width: 100%;
    max-width: 500px;
    font-family: var(--font8);
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}
/* RIGHT */
.right{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.feature{
    display: flex;
    width: 100%;
    background-color: color(display-p3 0.525 0.99 0.982 / 0.1);
    border-radius: 16px;
    padding: 24px 12px;
    box-sizing: border-box;
    margin-bottom: 16px;
}


/* FACTS */
.row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}
.fact_container {
    width: 30%;
    position: relative; /* Ensure the parent has a relative position */
    padding: 45px 55px;
    box-sizing: border-box;
}
.fact_container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 120px; /* Set width as needed */
    height: 100%; /* Set height as needed */
    background-image: url('./../../assets/img/other/fact_left_upper.svg');
    background-repeat: no-repeat;
}
.fact_container::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px; /* Set width as needed */
    height: 100%; /* Set height as needed */
    background-image: url('./../../assets/img/other/fact_right_down.svg');
    background-repeat: no-repeat;
    transform: translate(0, 50%);
}
.fact_label{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    margin-bottom: 12px;
}
.fact_text{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.75);
}
/* TEAM */
.team_row{
    display: flex;
    justify-content: space-between;
    margin: 100px 0 80px;
}
.team_container {
    width: 25%;
    position: relative; /* Ensure the parent has a relative position */
    padding: 0 55px;
    box-sizing: border-box;
    min-height: 300px;
}
.team_container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; /* Set width as needed */
    height: 100%; /* Set height as needed */
    background-image: url('./../../assets/img/by_page/about/team_left_upper.svg');
    background-repeat: no-repeat;
}
.team_container::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%; /* Set width as needed */
    height: 100%; /* Set height as needed */
    background-image: url('./../../assets/img/by_page/about/team_right_down.svg');
    background-repeat: no-repeat;
    transform: translate(50%, 50%);
}
.photo{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.label_block{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    backdrop-filter: blur(16px);
    background-color: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(153, 153, 153, 0.05) 100%);
    border-radius: 24px;
    width: 80%;
    left: 0;
    right: 0;
    bottom: 25px;
    margin: 0 auto;
    padding: 12px 0;
}
.label_left{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}
.label_right{
    width: 30%;
}
.team_title{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #4bfbf7;
    text-align: center;
}
.team_name{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}
.team_exp{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}