.section{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
}
.section img{
    margin-right: 32px;
}
.title{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 48px;
    color: #fff;
}