.section{
    z-index: 1;
    /* margin-bottom: 80px; */
    width: calc(100% - 60px);
}
.body{
    display: flex;
    flex-direction: column;
    margin-top: 64px;
}
/* .row1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
}
.row2{
    display: flex;
    justify-content: center;
} */
.row{
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
}
.card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22%;
    min-height: 390px;
    cursor: pointer;
}
.card:hover{
    background-image: url('./../../assets/img/by_page/main/item_highlight.svg');
    background-position: 100% -30%;
    background-repeat: no-repeat;
    background-size: 100%;
}
.card:hover :global(#right_upper_corner),
.card:hover :global(#right_down_corner){
    stroke:url(#SVGID_000001320561485149871618200000162554694292130507573_)!important;
    stroke-opacity: 1!important;
}
.card:not(:last-child){
    margin-right: 4%;
}
.card_bg{
    width: 100%;
    z-index: 1;
    position: absolute;
}
.img_block{
    display: flex;
    flex: 1;
    width: 75%;
    position: relative;
}
.card_img{
    width: 100%;
    z-index: 2;
    position: absolute;
    box-sizing: border-box;
    top: -30px;
}
.cardTitle{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    color: #cecece;
    margin-bottom: 8px;
    height: 55px;
    display: flex;
    align-items: center;
}

/*  */
.buttons_block{
    display: flex;
    flex-direction: column;
    width: 85%;
    z-index: 2;
    position: relative;
    margin: 0 auto 50px;
}
.buttons_info_portfolio{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.btn{
    border-radius: 8px;
    padding: 12px 16px;
    background: rgba(10, 103, 101, 0.28);

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    /* color: #078d8c; */
    color: var(--accent);
    margin: 4px 0;
}
.btn_info{
    width: 35%;
}
.btn_portfolio{
    width: 63%;
}
.btn_order{
    width: 100%;
}