.section{
    z-index: 1;
    margin-bottom: 80px;
    width: calc(100% - 60px);
}
.body{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
